
import {computed, defineComponent, ref, watch} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {useI18n} from "vue-i18n";
import { onLoad } from "@sentry/vue";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup()
  {
    const store = useStore();
    const { t } = useI18n();

    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));

    const submitButton = ref<HTMLButtonElement | null>(null)
    const forgotPassword = ref({});

    watch(currentLanguage, () => {
     forgotPassword.value =  Yup.object().shape({
        email: Yup.string().email(t(`${currentLanguage.value}.messages.the_email_must_be_a_valid_email`)).required(t(`${currentLanguage.value}.messages.email_is_a_required_field`)).label("Email"),
      });
    }, { immediate: true });

    //Form submit function
    const onSubmitForgotPassword = (values) => {
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // dummy delay

      // Send login request
      store
          .dispatch(Actions.FORGOT_PASSWORD, values)
          .then(() => {
            Swal.fire({
              text: t(`${currentLanguage.value}.messages.request_sent_successfully_check_your_email_to_reset_your_password`),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
            localStorage.setItem("email", values.email);
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            // Alert then login failed
            Swal.fire({
              text: t(`${currentLanguage.value}.messages.we_had_a_problem_requesting_a_password_reset_check_that_the_email_is_correct`),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t(`${currentLanguage.value}.messages.try_again`),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;

    };


    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
      currentLanguage
    };
  }
,
});
